var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-row',{attrs:{"title":"Szolgáltatások"}}),(_vm.services.uniom)?_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("Difusion szolgáltatások")]),_c('v-data-table',{attrs:{"items":_vm.services.uniom,"headers":[
      { text: 'Név', value: 'Name' },
      { text: 'Státusz', value: 'State' },
    ],"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.State`,fn:function({ item }){return [(item.State === 'running')?_c('span',{staticClass:"success white--text pa-2 rounded-lg"},[_vm._v("Aktív")]):(item.State === 'exited')?_c('span',{staticClass:"error white--text pa-2 rounded-lg"},[_vm._v("Inaktív")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.services.huniom)?_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("Hikvision szolgáltatások")]),_c('v-data-table',{attrs:{"items":_vm.services.huniom,"headers":[
      { text: 'Név', value: 'name' },
      { text: 'Státusz', value: 'state' },
      { text: 'Uptime', value: 'Status' },
      { value: 'actions', align: 'end' },
    ],"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('a',{attrs:{"href":`https://beleptetoadmin.sze.hu/portainer#!/2/docker/containers/${item.Id}`,"target":"_blank"}},[(item.Names[0] === '/huniom-remote-control-1')?_c('span',[_vm._v("Távoli nyitás")]):(item.Names[0] === '/huniom-log-1')?_c('span',[_vm._v("Naplózás")]):(item.Names[0] === '/huniom-synchronize-1')?_c('span',[_vm._v("Jogosultság szinkronizálás")]):_vm._e()])]}},{key:`item.state`,fn:function({ item }){return [(item.State === 'running')?_c('span',{staticClass:"success white--text pa-2 rounded-lg"},[_vm._v("Aktív")]):(item.State === 'exited')?_c('span',{staticClass:"error white--text pa-2 rounded-lg"},[_vm._v("Inaktív")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(_vm.hasRight('SERVICE'))?[(_vm.loading[item.Id])?[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})]:[(item.State === 'running')?[_c('v-btn',{staticClass:"rounded-sm",attrs:{"title":"Újraindítás","text":"","fab":"","small":"","color":"warning"},on:{"click":function($event){return _vm.restartService(item)}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{staticClass:"rounded-sm",attrs:{"title":"Leállítás","text":"","fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.stopService(item)}}},[_c('v-icon',[_vm._v("mdi-stop")])],1)]:(item.State === 'exited')?_c('v-btn',{staticClass:"rounded-sm",attrs:{"text":"","fab":"","small":"","title":"Elindítás","color":"primary"},on:{"click":function($event){return _vm.startService(item)}}},[_c('v-icon',[_vm._v("mdi-play")])],1):_vm._e()]]:_vm._e()]}}],null,true)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }