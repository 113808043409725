<template>
  <div>

    <title-row title="Szolgáltatások" />

    <v-card v-if="services.uniom" class="mb-6">
      <v-card-title>Difusion szolgáltatások</v-card-title>
      <v-data-table :items="services.uniom" :headers="[
        { text: 'Név', value: 'Name' },
        { text: 'Státusz', value: 'State' },
      ]" :items-per-page="10" hide-default-footer>
        <template #[`item.State`]="{ item }">
          <span v-if="item.State === 'running'" class="success white--text pa-2 rounded-lg">Aktív</span>
          <span v-else-if="item.State === 'exited'" class="error white--text pa-2 rounded-lg">Inaktív</span>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-if="services.huniom" class="mb-6">
      <v-card-title>Hikvision szolgáltatások</v-card-title>
      <v-data-table :items="services.huniom" :headers="[
        { text: 'Név', value: 'name' },
        { text: 'Státusz', value: 'state' },
        { text: 'Uptime', value: 'Status' },
        { value: 'actions', align: 'end' },
      ]" :items-per-page="10" hide-default-footer>

        <template #[`item.name`]="{ item }">
          <a :href="`https://beleptetoadmin.sze.hu/portainer#!/2/docker/containers/${item.Id}`" target="_blank">
            <span v-if="item.Names[0] === '/huniom-remote-control-1'">Távoli nyitás</span>
            <span v-else-if="item.Names[0] === '/huniom-log-1'">Naplózás</span>
            <span v-else-if="item.Names[0] === '/huniom-synchronize-1'">Jogosultság szinkronizálás</span>
          </a>
        </template>

        <template #[`item.state`]="{ item }">
          <span v-if="item.State === 'running'" class="success white--text pa-2 rounded-lg">Aktív</span>
          <span v-else-if="item.State === 'exited'" class="error white--text pa-2 rounded-lg">Inaktív</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <template v-if="hasRight('SERVICE')">

            <template v-if="loading[item.Id]">
              <v-progress-circular indeterminate color="primary" />
            </template>

            <template v-else>
              <template v-if="item.State === 'running'">
                <v-btn title="Újraindítás" text fab small class="rounded-sm" color="warning"
                  @click="restartService(item)">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-btn title="Leállítás" text fab small class="rounded-sm" color="error" @click="stopService(item)">
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </template>
              <v-btn v-else-if="item.State === 'exited'" text fab small class="rounded-sm" title="Elindítás"
                color="primary" @click="startService(item)">
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
          </template>
        </template>

      </v-data-table>
    </v-card>

  </div>
</template>

<script>
let interval = null;

export default {
  data() {
    return {
      services: {},
      loading: {},
    }
  },

  async mounted() {
    const response = await this.$http.get('services/list');
    this.services = response.services;
    interval = setInterval(async () => {
      const response = await this.$http.get('services/list');
      this.services = response.services;
    }, 3000);
  },

  destroyed() {
    clearInterval(interval);
  },

  methods: {
    async restartService(service) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `A szolgáltatás újraindítására készül. Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        this.loading[service.Id] = false;
        return;
      }

      this.loading[service.Id] = true;
      await this.$http.post(`services/restart`, { id: service.Id });
      this.loading[service.Id] = false;
    },

    async stopService(service) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `A szolgáltatás leállítására készül. Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        this.loading[service.Id] = false;
        return;
      }

      this.loading[service.Id] = true;
      this.$http.post(`services/stop`, { id: service.Id });
      this.loading[service.Id] = false;
    },

    async startService(service) {
      this.loading[service.Id] = true;
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `A szolgáltatás elindítására készül. Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        this.loading[service.Id] = false;
        return;
      }

      await this.$http.post(`services/start`, { id: service.Id });
      this.loading[service.Id] = false;
    }
  }

};
</script>